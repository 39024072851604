<template>
  <ui-component-modal
    modalTitle="Delete package"
    buttonLabelConfirm="Delete"
    confirmButtonCssClass="is-danger"
    :isSaving="isDeleting"
    :isSavingSuccess="isDeletingSuccess"
    :isSavingError="isDeletingError"
    :hideFooterButtons="isDeletingError || isDeletingSuccess"
    :disableSaveButton="!isDeleteConfirmed"
    :onClickCancel="onClickCancel"
    :onClickSave="deletePackage"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template v-slot:content>
      <div class="has-margin-bottom is-size-5 has-text-centered">
        Are you sure you want to remove this package?
      </div>
      <div class="has-text-centered">
        <BaseCheckbox
          v-model="isDeleteConfirmed"
          class="has-text-left"
          :label="'Yes, I am sure!'"
        />
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapActions } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'

const BaseCheckbox = () => import('@/components/UI/Form/BaseCheckbox')

export default {
  components: {
    BaseCheckbox,
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },

    packageId: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      isDeleteConfirmed: false,
      isDeleting: false,
      isDeletingSuccess: false,
      isDeletingError: false,
    }
  },

  computed: {},

  created() {},

  methods: {
    ...mapActions('packageStore', ['deletePackageData']),

    deletePackage() {
      let self = this

      if (!self.isDeleting) {
        self.isDeleting = true

        self
          .deletePackageData(self.packageId)
          .then((response) => {
            if (response.status === 204) {
              // Send message to display toast on screen
              EventBus.$emit('showToast', {
                type: 'info',
                message: 'Package deleted!',
              })

              self.$emit('packageDeleted', self.packageId)

              self.onClickCancel()
            }
          })
          .finally(() => {
            self.isDeleting = false
          })

        // packageProvider.methods
        //   .deletePackage(self.packageObj.Id)
        //   .then((response) => {
        //     if (response.status === 204) {
        //       self.isDeletingSuccess = true

        //       self.$emit('packageDeleted', self.packageObj)

        //       EventBus.$emit('showToast', {
        //         type: 'info',
        //         message: 'Bundle deleted!',
        //       })

        //       self.onClickCancel()
        //     }
        //   })
        //   .catch((error) => {
        //     self.isDeletingError = true
        //   })
        //   .finally(() => {
        //     self.isDeleting = false
        //   })
      }
    },
  },
}
</script>
